export class SearchHandler {
    constructor() {
        this.initialGamesLoaded = false; // Track if initial games are already loaded
        this.init();
    }

    init() {
        [...document.querySelectorAll('.search-block')].forEach(searchBlock => {
            const searchModal = searchBlock.querySelector('.search-modal');
            const searchOpenBtnAll = document.querySelectorAll('.search-button');
            const searchCloseBtn = searchModal?.querySelector('.close-modal');
            const searchInput = searchBlock.querySelector('.search-input');
            const resultContainer = searchBlock.querySelector('.games-block-recommended__list');

            searchOpenBtnAll.forEach(searchOpenBtn => {
                searchOpenBtn?.addEventListener('click', () => {
                    this.toggleModal(searchModal, true);
                    if (!this.initialGamesLoaded) {
                        this.loadInitialGames(resultContainer);
                        this.initialGamesLoaded = true; // Prevent multiple initial loads
                    }
                });
            });

            // Close modal
            searchCloseBtn?.addEventListener('click', () => {
                this.toggleModal(searchModal, false);
            });

            // Handle search input
            searchInput?.addEventListener('input', debounce(event => {
                const searchText = event.target.value.trim();
                if (searchText.length > 1) {
                    this.fetchGames(searchText, resultContainer);
                } else if (!searchText.length) {
                    this.loadInitialGames(resultContainer); // Reload initial games when input is cleared
                }
            }, 300)); // Debounce to limit excessive API calls

            // Add click listener to result container for delegation
            resultContainer.addEventListener('click', (event) => {
                const target = event.target.closest('.games-block-recommended__link');
                if (target) {
                    event.preventDefault();
                    this.handleGameLinkClick(target);
                }
            });
        });
    }

    toggleModal(modal, show) {
        if (modal) {
            modal.style.display = show ? 'block' : 'none';
            document.body.classList.toggle('modal-open', show);
        }
    }

    async loadInitialGames(container) {
        if (!this.initialGamesLoaded) { // Ensure this is only called once per session
            await this.fetchGames('', container); // Load initial games with empty filter
        }
    }

    async fetchGames(query, container) {
        const API_URL = myScriptData.CASINO_API;
        const apiUrl = `${API_URL}/api/games`;
        let url = apiUrl;
      
        if (query && query.trim() !== '') {
          url += `?filter[name]=${encodeURIComponent(query)}`;
      
          try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('Network response was not ok.');
            const data = await response.json();
      
            if (data.data.games.length > 0) {
              this.updateUI(data.data.games, container);
              return; 
            }
          } catch (error) {
            console.error('Error fetching games by name:', error);
          }
      
          // If no games found by name, try searching by studio
          url = apiUrl;
          url += `?filter[studio]=${query.trim().replace(/ /g, '_')}`;
      
          try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('Network response was not ok.');
            const data = await response.json();
            this.updateUI(data.data.games, container);
          } catch (error) {
            console.error('Error fetching games by studio:', error);
          }
        } else {
          // Fetch all games if no query is provided
          try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('Network response was not ok.');
            const data = await response.json();
            this.updateUI(data.data.games, container);
          } catch (error) {
            console.error('Error fetching games:', error);
          }
        }
    }

    updateUI(games, container) {
        const fallbackThumbnail = 'https://static.rivieracasino.com/uploads/game-thumb.webp';
        if (container) {
            if (games.length === 0) {
                container.innerHTML = 'No matching games found.';
            } else {
                let languagePrefix = this.getLanguagePrefix();
                languagePrefix = languagePrefix == 'en' ? '' : '/' + languagePrefix;
                container.innerHTML = games.slice(0, 6).map(game => `
                    <li class="games-block-recommended__item">
                        <a href="${languagePrefix}/games/${game.slug}/" class="games-block-recommended__link">
                            <img src="${game.url || fallbackThumbnail}" alt="${game.name}" class="games-block-recommended__image">
                            <div class="games-block-recommended__content">
                                <span class="games-block-recommended__name">${game.name}</span>
                                <span class="games-block-recommended__provider">${game.studio.replace(/_/g, ' ')}</span>
                            </div>
                        </a>
                    </li>
                `).join('');
            }
        }
    }

    getLanguagePrefix() {
        const urlPath = window.location.pathname.split('/');
        const availableLanguages = ['es', 'de', 'it', 'pt', 'fr'];
        const currentLanguage = urlPath[1];
        return availableLanguages.includes(currentLanguage) ? currentLanguage : 'en';
    }

    handleGameLinkClick(target) {
        const sessionToken = localStorage.getItem('token');
        // If the user is logged in, allow navigation
        window.location.href = target.href;

    }
}

// Debounce function to limit how often a function can fire
function debounce(func, wait, immediate) {
    let timeout;
    return function() {
        const context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        }, wait);
        if (immediate && !timeout) func.apply(context, args);
    };
}

new SearchHandler();
